import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    is_kepala: false,
    is_musyrif: false,
    is_mentoring: false,
    is_tahfidz: false,
    is_waka: false,
    is_super_user: false,
    user: {},
  },
  mutations: {
    setRole(state, data) {
      state.is_kepala = data.is_kepala;
      state.is_musyrif = data.is_musyrif;
      state.is_mentoring = data.is_mentoring;
      state.is_tahfidz = data.is_tahfidz;
      state.is_waka = data.is_waka;
      state.is_super_user = data.is_super_user;
    },
    setCurrentUser(state, data) {
      state.user = data;
      // console.log(state.user);
    },
    clearState(state) {
      state = {
        is_kepala: false,
        is_musyrif: false,
        is_mentoring: false,
        is_tahfidz: false,
        is_waka: false,
        is_super_user: false,
        user: {},
      };
    },
  },
  actions: {
    async sendCurrentUser(state) {
      var header = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      let resp = await Vue.prototype.$http.get("/user_data", header);
      state.commit("setCurrentUser", resp.data);
      // console.log(resp.data);
      // console.log("complete role");
    },
  },
  modules: {},
  getters: {
    async getCurrentUser(state) {
      return state.user;
    },
  },
});
