import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";

Vue.config.productionTip = false;

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
Vue.prototype.$http = axios;
Vue.prototype.$isLogin = false;

import helpers from "@/helpers";
Vue.use(helpers);
Vue.use(require("vue-moment"));

import Vue2Filters from "vue2-filters";

Vue.prototype.$LIST_JENJANG = ["SMP PA", "SMP PI", "MA"];
Vue.prototype.$LIST_PENGASUHAN = ["PENGASUHAN PUTRA", "PENGASUHAN PUTRI"];
Vue.directive("can", {
  bind: (el, binding, vnode) => {
    // vuex diisi saat sukses login
    let store = vnode.context.$store;
    // hak akses portal akademik: MUSYRIF, TAHFIDZ, MENTORING, ADMIN, KEPALA
    if (binding.value.includes("ADMIN") && store.state.is_admin) {
      return;
    } else if (binding.value.includes("KEPALA") && store.state.is_kepala) {
      return;
    } else if (binding.value.includes("MUSYRIF") && store.state.is_musyrif) {
      return;
    } else if (binding.value.includes("TAHFIDZ") && store.state.is_tahfidz) {
      return;
    } else if (binding.value.includes("MENTOR") && store.state.is_mentoring) {
      return;
    } else if (binding.value.includes("WAKA") && store.state.is_waka) {
      return;
    } else if (store.state.is_super_user) {
      return;
    } else {
      vnode.elm.style.display = "none";
    }
  },
});
Vue.use(Vue2Filters);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
