import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login"),
  },

  //penilaian area
  {
    path: "/penilaianabsensi",
    name: "Absensi",
    component: () => import("../views/PenilaianAbsensi"),
  },
  {
    path: "/penilaianriwayatabsensi",
    name: "Riwwayat Absensi",
    component: () => import("../views/PenilaianRiwayatAbsensi"),
  },
  {
    path: "/penilaiantahfidz/:id/:tipe",
    name: "Setoran Tahfidz",
    component: () => import("../views/PenilaianTahfidz"),
  },
  {
    path: "/penilaiantahfidzabsensi",
    name: "Absensi Tahfidz",
    component: () => import("../views/PenilaianTahfidzAbsensi"),
  },
  {
    path: "/penilaianremedialtahfidz/:id/:tipe",
    name: "Remedial Tahfidz",
    component: () => import("../views/PenilaianRemedialTahfidz"),
  },
  {
    path: "/penilaianriwayattahfidz/:id/:nama",
    name: "Riwayat Tahfidz",
    component: () => import("../views/PenilaianRiwayatTahfidz"),
  },
  /*   {
      path: '/penilaianinputnilai',
      name: 'Input Penilaian',
      component: () => import('../views/PenilaianInputNilai')
    }, */
  {
    path: "/penilaianinputnilaimobile",
    name: "Input Penilaian",
    component: () => import("../views/PenilaianInputNilaiMobile"),
  },
  {
    path: "/penilaianinputcatatan",
    name: "Input Catatan",
    component: () => import("../views/PenilaianInputCatatan"),
  },
  {
    path: "/penilaianinputpembinaan/:id",
    name: "Input Pembinaan",
    component: () => import("../views/PenilaianInputPembinaan"),
  },
  //end penilaian

  //hasil area
  {
    path: "/hasilevaluasiabsensi",
    name: "Evaluasi Absensi",
    component: () => import("../views/HasilEvaluasiAbsensi"),
  },
  {
    path: "/hasilpencapaiantahfidz",
    name: "Pencapaian Tahfidz",
    component: () => import("../views/HasilPencapaianTahfidz"),
  },
  {
    path: "/hasilrekappembinaan/:id",
    name: "Rekapitulasi Pembinaan",
    component: () => import("../views/HasilRekapPembinaan"),
  },
  {
    path: "/hasil/laporan_akademik",
    name: "Laporan Akademik",
    component: () => import("../views/HasilLaporanAkademik"),
  },
  {
    path: "/hasilraportdigital",
    name: "Raport Digital",
    component: () => import("../views/HasilRaportDigital"),
  },
  //end hasil

  //pengaturan area
  {
    path: "/pengaturansiswabimbingan",
    name: "Siswa Bimbingan",
    component: () => import("../views/PengaturanSiswaBimbingan"),
  },
  {
    path: "/pengaturaneditbimbingan/:id/:tipe",
    name: "Tambah Siswa Bimbingan",
    component: () => import("../views/PengaturanEditBimbingan"),
  },
  {
    path: "/pengaturaneditakun",
    name: "Edit Akun",
    component: () => import("../views/PengaturanEditAkun"),
  },
  {
    path: "/pengaturaneditpassword",
    name: "Edit Password",
    component: () => import("../views/PengaturanEditPassword"),
  },
  {
    path: "/pengaturanaspek",
    name: "Aspek Penilaian",
    component: () => import("../views/PengaturanAspek"),
  },
  {
    path: "/pengaturandaftarkamar/:id_asrama",
    name: "Daftar Kamar",
    component: () => import("../views/PengaturanDaftarKamar"),
  },
  {
    path: "/pengaturananggotakamar/:id",
    name: "Anggota Kamar",
    component: () => import("../views/PengaturanAnggotaKamar"),
  },
  {
    path: "/pengaturandaftarmusyrif",
    name: "Daftar Musyrif",
    component: () => import("../views/PengaturanDaftarMusyrif"),
  },
  {
    path: "/pengaturananggotamusyrif/:id",
    name: "Anggota Musyrif",
    component: () => import("../views/PengaturanAnggotaMusyrif"),
  },
  {
    path: "/pengaturan/laporan_kerusakan",
    name: "Laporan Kerusakan",
    component: () => import("../views/PengaturanLaporanKerusakan"),
  },
  {
    path: "/pengaturan/absensi_musyrif",
    name: "Absensi Musyrif",
    component: () => import("../views/PengaturanAbsensiMusyrif"),
  },
  {
    path: "/pengaturandaftarsiswa",
    name: "Daftar Siswa",
    component: () => import("../views/PengaturanDaftarSiswa"),
  },
  //end pengaturan
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  var toLogin = to.name == "Login";
  var isUser = localStorage.getItem("token") != null;
  var toHome = to.name == "Home";

  // hide header if not login page
  if (toLogin) {
    Vue.prototype.$isLogin = true;
  } else {
    Vue.prototype.$isLogin = false;
  }

  if (toHome) {
    Vue.prototype.$isHome = true;
  } else {
    Vue.prototype.$isHome = false;
  }

  // router guards
  if (!toLogin && !isUser) {
    next("/login");
  } else if (!toLogin && isUser) {
    next();
  } else if (toLogin && isUser) {
    next("/");
  } else if (toLogin && !isUser) {
    next();
  }
});

export default router;
