import Vue from 'vue'

import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Ripple from 'quasar/src/directives/Ripple.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';

Vue.use(Quasar, {
  config: {},
  directives: {
    Ripple
  },
  plugins: {
    Notify,
    Dialog,
    Loading,
    ClosePopup
  },
 })